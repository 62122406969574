/* eslint-disable jsx-a11y/anchor-is-valid */
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import Particles from 'react-tsparticles';
import { PageWrapper } from '../atoms';
import { DnaAtom, DnaItemProps, SectionHeader } from '../molecules';

import leftIllustration from '../../images/ADN-elmnt2_white.svg';
import rightIllustration from '../../images/ADN-secondary.svg';
import { breakpoints, dimensions } from '../../styles/variables';

const HeaderWrapper = styled(PageWrapper)`
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
`;

const DNAWrapper = styled(PageWrapper)`
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Background = styled.div`
  height: 100%;
`;

const MobileContainer = styled.div`
  margin: 0 ${dimensions.space.base};
  display: block;
  @media screen and (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

const DesktopContainer = styled.div`
  display: none;
  @media screen and (min-width: ${breakpoints.md}) {
    display: block;
  }
`;

const breathing = (initialRadius, finalRadius) => {
  return keyframes`
    0% {
      r: ${initialRadius};
    }

    60% {
      r: ${finalRadius};
    }
    
    95% {
      r: ${initialRadius};
    }

    100% {
      r: ${initialRadius};
    }  
  `;
};

const DnaParticles = styled(Particles)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #d7cbe4;
`;

const rotate = (firstRotation, secondRotation) => {
  return keyframes`
  0% {
    transform: scale(1) rotate(0deg);
  }

  25% {
    transform: scale(1.05) rotate(${firstRotation}deg);
  }

  50% {
    transform: scale(1) rotate(0deg);
  }

  75% {
    transform: scale(1.05) rotate(${secondRotation}deg);
  }

  100% {
    transform: scale(1) rotate(0deg);
  }
  `;
};

const MobileDna = styled(MobileContainer)`
  width: 100%;
  height: 100%;
`;

const DesktopDna = styled(DesktopContainer)`
  width: 100%;
  height: 100%;
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.3));
`;

const BackgroundBottomAtom = styled.div`
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 25vw;
  height: 25vw;
  animation: ${rotate(3, -2)} 20s ease infinite;
  filter: blur(3px);
  z-index: -1;
`;

const BackgroundTopAtom = styled.div`
  position: absolute;
  top: 17vw;
  right: -5px;
  width: 50vw;
  height: 50vw;
  animation: ${rotate(-2, 3)} 20s ease infinite;
  filter: blur(2px);
  z-index: -1;
  @media screen and (min-width: ${breakpoints.md}) {
    top: 65px;
    right: -20px;
  }
`;

export type DnasProps = {
  title: string;
  pretitle: string;
  values: DnaItemProps[];
};

export default function D({ title, pretitle, values }: DnasProps) {
  return (
    <Background>
      <DnaParticles
        id="tsparticles"
        options={{
          fpsLimit: 60,
          interactivity: {
            detectsOn: 'canvas',
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 1,
                size: 40,
              },
            },
          },
          particles: {
            color: {
              value: ['#ffffff'],
            },
            links: {
              color: '#ffffff',
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: 'none',
              enable: true,
              outMode: 'bounce',
              random: false,
              speed: 0.8,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                value_area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: 'circle',
            },
            size: {
              random: true,
              value: 5,
            },
          },
          detectRetina: true,
        }}
      />
      <MobileContainer style={{ position: 'absolute' }}>
        <svg style={{ width: '100vw', height: '50px', margin: `0 -${dimensions.space.base}` }}>
          <ellipse fill="white" rx="55%" ry="125%" cx="50%" cy="-25%" />
        </svg>
      </MobileContainer>
      <DesktopContainer>
        <HeaderWrapper>
          <SectionHeader title={title} pretitle={pretitle} />
        </HeaderWrapper>
      </DesktopContainer>
      <BackgroundBottomAtom>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="368.097"
          height="389.924"
          viewBox="0 0 368.097 389.924"
          css={css`
            height: 100%;
            width: 100%;
          `}
        >
          <g id="ADN-elmnt2_white" transform="translate(40.954 -1117)">
            <circle
              id="Ellipse_72"
              data-name="Ellipse 72"
              cx="46.477"
              cy="46.477"
              r="46.477"
              transform="translate(-40.954 1117)"
              fill="#fff"
            />
            <line
              id="Ligne_16"
              data-name="Ligne 16"
              x2="297"
              y2="316"
              transform="translate(26.5 1187.5)"
              fill="none"
              stroke="#fff"
              strokeWidth="10"
            />
          </g>
        </svg>
      </BackgroundBottomAtom>
      <BackgroundTopAtom>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="775.3"
          height="682.4"
          viewBox="0 0 775.3 682.4"
          css={css`
            height: 100%;
            width: 100%;

            .st0 {
              fill: none;
              stroke: #ffffff;
              stroke-width: 10;
              stroke-miterlimit: 10;
            }
            .st1 {
              fill: #ffffff;
            }
            .st2 {
              fill: none;
              stroke: #ffffff;
              stroke-width: 10;
            }
          `}
        >
          <line id="Ligne_8" className="st0" x1="542.5" y1="445.5" x2="399.5" y2="260.5" />
          <line id="Ligne_9" className="st0" x1="774.5" y1="201.5" x2="399.5" y2="263.5" />
          <line id="Ligne_10" className="st0" x1="251.5" y1="91.5" x2="399.5" y2="263.5" />
          <circle id="Ellipse_65" className="st1" cx="254.9" cy="71.5" r="46.5" />
          <circle id="Ellipse_66" className="st1" cx="399.5" cy="260.4" r="46.5" />
          <circle id="Ellipse_67" className="st1" cx="542.9" cy="446" r="46.5" />
          <circle id="Ellipse_68" className="st1" cx="75.5" cy="598.9" r="46.5" />
          <line id="Ligne_15" className="st2" x1="391.5" y1="263.5" x2="83.5" y2="592.5" />
        </svg>
      </BackgroundTopAtom>

      <DNAWrapper>
        <MobileDna>
          <svg
            css={css`
              height: 100%;
              width: 100%;

              .st0 {
                opacity: 0.2;
                fill: #62318a;
                enable-background: new;
              }

              .st1 {
                fill: #62318a;
                stroke-width: 0;
                stroke: #62318a;
                stroke-opacity: 0.2;
                transition: stroke-width 0.3s ease-in-out;
              }
              a:hover .st1,
              a:focus .st1 {
                stroke-width: 15px;
              }
              .st2 {
                enable-background: new;
              }
              .st3 {
                fill: #ffffff;
              }
              .st4 {
                fill: none;
                stroke: #d7cbe4;
              }
              .st5 {
                fill: none;
                stroke: #62318a;
                stroke-width: 10;
              }
            `}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 432.6 755"
            xmlSpace="preserve"
          >
            <g id="ADN-main" transform="translate(158 -20.227)">
              <g id="ADN-Ap">
                <DnaAtom {...values.find((o) => o.name.toLowerCase() === 'accompagnement')}>
                  <g id="ACCOMPAGNEMENT" transform="translate(-95.38 528.635)">
                    <ellipse id="circle-accompagnement" className="st1" cx="120.4" cy="120.1" rx="107" ry="107.5" />
                    <g id="Legend" transform="translate(-4.235 -14.409)">
                      <g className="st2">
                        <path
                          className="st3"
                          d="M57.5,190.6h-5.2l-1.1,2.5h-1.4l4.4-9.8h1.4l4.5,9.8h-1.5L57.5,190.6z M57,189.5l-2.1-4.8l-2.1,4.8H57z"
                        />
                        <path
                          className="st3"
                          d="M63.6,192.6c-0.6-0.3-1.1-0.8-1.4-1.4c-0.3-0.6-0.5-1.2-0.5-2c0-0.7,0.2-1.4,0.5-2c0.3-0.6,0.8-1,1.4-1.3
						s1.3-0.5,2-0.5c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,0.9,0.7,1.2,1.2l-1,0.7c-0.2-0.4-0.5-0.6-0.9-0.8c-0.3-0.2-0.7-0.3-1.1-0.3
						c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.5-0.9,0.9c-0.2,0.4-0.3,0.9-0.3,1.4c0,0.5,0.1,1,0.3,1.4s0.5,0.7,0.9,0.9
						c0.4,0.2,0.8,0.3,1.3,0.3c0.4,0,0.8-0.1,1.1-0.3c0.3-0.2,0.6-0.4,0.9-0.8l1,0.6c-0.3,0.5-0.7,0.9-1.2,1.2
						c-0.5,0.3-1.1,0.4-1.8,0.4C64.9,193.1,64.2,193,63.6,192.6z"
                        />
                        <path
                          className="st3"
                          d="M72.6,192.6c-0.6-0.3-1.1-0.8-1.4-1.4c-0.3-0.6-0.5-1.2-0.5-2c0-0.7,0.2-1.4,0.5-2c0.3-0.6,0.8-1,1.4-1.3
						s1.3-0.5,2-0.5c0.7,0,1.3,0.1,1.8,0.4c0.5,0.3,0.9,0.7,1.2,1.2l-1,0.7c-0.2-0.4-0.5-0.6-0.9-0.8c-0.3-0.2-0.7-0.3-1.1-0.3
						c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.5-0.9,0.9c-0.2,0.4-0.3,0.9-0.3,1.4c0,0.5,0.1,1,0.3,1.4s0.5,0.7,0.9,0.9
						c0.4,0.2,0.8,0.3,1.3,0.3c0.4,0,0.8-0.1,1.1-0.3c0.3-0.2,0.6-0.4,0.9-0.8l1,0.6c-0.3,0.5-0.7,0.9-1.2,1.2
						c-0.5,0.3-1.1,0.4-1.8,0.4C73.9,193.1,73.2,193,72.6,192.6z"
                        />
                        <path
                          className="st3"
                          d="M81.6,192.6c-0.6-0.3-1-0.8-1.4-1.4c-0.3-0.6-0.5-1.2-0.5-2c0-0.7,0.2-1.4,0.5-2c0.3-0.6,0.8-1,1.4-1.3
						s1.3-0.5,2-0.5c0.7,0,1.4,0.2,2,0.5c0.6,0.3,1,0.8,1.4,1.3c0.3,0.6,0.5,1.2,0.5,2c0,0.7-0.2,1.4-0.5,2c-0.3,0.6-0.8,1-1.4,1.4
						c-0.6,0.3-1.2,0.5-2,0.5C82.8,193.1,82.2,193,81.6,192.6z M84.9,191.6c0.4-0.2,0.7-0.5,0.9-0.9c0.2-0.4,0.3-0.9,0.3-1.4
						s-0.1-1-0.3-1.4c-0.2-0.4-0.5-0.7-0.9-0.9s-0.8-0.3-1.3-0.3s-0.9,0.1-1.3,0.3s-0.7,0.5-0.9,0.9c-0.2,0.4-0.3,0.9-0.3,1.4
						s0.1,1,0.3,1.4s0.5,0.7,0.9,0.9s0.8,0.3,1.3,0.3S84.5,191.9,84.9,191.6z"
                        />
                        <path
                          className="st3"
                          d="M101.9,186.4c0.5,0.5,0.8,1.3,0.8,2.4v4.3h-1.3v-4.1c0-0.7-0.2-1.3-0.5-1.6c-0.3-0.4-0.8-0.5-1.4-0.5
						c-0.7,0-1.2,0.2-1.6,0.6c-0.4,0.4-0.6,1-0.6,1.8v3.8h-1.3v-4.1c0-0.7-0.2-1.3-0.5-1.6c-0.3-0.4-0.8-0.5-1.4-0.5
						c-0.7,0-1.2,0.2-1.6,0.6c-0.4,0.4-0.6,1-0.6,1.8v3.8h-1.3v-7.4h1.3v1.1c0.3-0.4,0.6-0.7,1.1-0.9c0.4-0.2,0.9-0.3,1.5-0.3
						c0.6,0,1.1,0.1,1.5,0.4c0.4,0.2,0.8,0.6,1,1c0.3-0.4,0.7-0.8,1.2-1c0.5-0.2,1.1-0.4,1.7-0.4
						C100.6,185.6,101.4,185.8,101.9,186.4z"
                        />
                        <path
                          className="st3"
                          d="M112.2,186c0.6,0.3,1,0.8,1.3,1.3c0.3,0.6,0.5,1.2,0.5,2s-0.2,1.4-0.5,2c-0.3,0.6-0.8,1-1.3,1.3
						c-0.6,0.3-1.2,0.5-1.9,0.5c-0.5,0-1-0.1-1.5-0.3c-0.4-0.2-0.8-0.5-1.1-0.9v3.8h-1.3v-10.1h1.3v1.2c0.3-0.4,0.7-0.7,1.1-0.9
						c0.5-0.2,1-0.3,1.5-0.3C111,185.6,111.6,185.7,112.2,186z M111.4,191.6c0.4-0.2,0.7-0.5,0.9-0.9s0.3-0.9,0.3-1.4
						s-0.1-1-0.3-1.4c-0.2-0.4-0.5-0.7-0.9-0.9s-0.8-0.3-1.3-0.3c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.5-0.9,0.9
						c-0.2,0.4-0.3,0.8-0.3,1.4s0.1,1,0.3,1.4c0.2,0.4,0.5,0.7,0.9,0.9s0.8,0.3,1.3,0.3C110.6,192,111,191.9,111.4,191.6z"
                        />
                        <path
                          className="st3"
                          d="M122,186.3c0.5,0.5,0.8,1.3,0.8,2.3v4.5h-1.3v-1c-0.2,0.3-0.5,0.6-1,0.8c-0.4,0.2-0.9,0.3-1.5,0.3
						c-0.8,0-1.5-0.2-2-0.6c-0.5-0.4-0.7-0.9-0.7-1.6s0.2-1.2,0.7-1.6s1.2-0.6,2.3-0.6h2.1v-0.3c0-0.6-0.2-1-0.5-1.3
						c-0.3-0.3-0.8-0.5-1.5-0.5c-0.4,0-0.9,0.1-1.3,0.2c-0.4,0.1-0.8,0.3-1.1,0.6l-0.6-1c0.4-0.3,0.8-0.5,1.4-0.7
						c0.5-0.2,1.1-0.2,1.7-0.2C120.7,185.6,121.4,185.8,122,186.3z M120.6,191.8c0.4-0.2,0.6-0.6,0.8-1v-1h-2.1
						c-1.1,0-1.7,0.4-1.7,1.1c0,0.4,0.1,0.7,0.4,0.9c0.3,0.2,0.7,0.3,1.2,0.3S120.3,192,120.6,191.8z"
                        />
                        <path
                          className="st3"
                          d="M133.6,185.6v6.4c0,1.3-0.3,2.3-1,2.9c-0.6,0.6-1.6,0.9-2.9,0.9c-0.7,0-1.4-0.1-2-0.3s-1.1-0.5-1.5-0.8
						l0.6-1c0.3,0.3,0.8,0.5,1.3,0.7c0.5,0.2,1,0.3,1.6,0.3c0.9,0,1.5-0.2,1.9-0.6c0.4-0.4,0.6-1,0.6-1.9v-0.6
						c-0.3,0.4-0.7,0.7-1.2,0.9s-1,0.3-1.5,0.3c-0.7,0-1.4-0.2-1.9-0.5c-0.6-0.3-1-0.7-1.4-1.3c-0.3-0.5-0.5-1.2-0.5-1.9
						s0.2-1.3,0.5-1.9c0.3-0.5,0.8-1,1.4-1.3c0.6-0.3,1.2-0.4,1.9-0.4c0.6,0,1.1,0.1,1.6,0.3c0.5,0.2,0.9,0.5,1.2,0.9v-1.1H133.6z
						 M131,191.3c0.4-0.2,0.7-0.5,0.9-0.9c0.2-0.4,0.3-0.8,0.3-1.3c0-0.7-0.2-1.3-0.7-1.7c-0.5-0.4-1.1-0.7-1.8-0.7
						c-0.8,0-1.4,0.2-1.8,0.7c-0.5,0.4-0.7,1-0.7,1.7c0,0.5,0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.7,0.9,0.9c0.4,0.2,0.8,0.3,1.3,0.3
						C130.2,191.6,130.6,191.5,131,191.3z"
                        />
                        <path
                          className="st3"
                          d="M143.4,186.4c0.6,0.5,0.8,1.3,0.8,2.4v4.3h-1.3v-4.1c0-0.7-0.2-1.3-0.5-1.6c-0.3-0.4-0.8-0.5-1.5-0.5
						c-0.7,0-1.3,0.2-1.7,0.6c-0.4,0.4-0.6,1-0.6,1.8v3.8h-1.3v-7.4h1.3v1.1c0.3-0.4,0.6-0.7,1.1-0.9s1-0.3,1.6-0.3
						C142.1,185.6,142.9,185.8,143.4,186.4z"
                        />
                        <path
                          className="st3"
                          d="M154.6,189.8h-6c0.1,0.7,0.4,1.2,0.9,1.6c0.5,0.4,1.1,0.6,1.8,0.6c0.9,0,1.6-0.3,2.1-0.9l0.7,0.9
						c-0.3,0.4-0.8,0.7-1.3,0.9c-0.5,0.2-1.1,0.3-1.7,0.3c-0.8,0-1.5-0.2-2.1-0.5c-0.6-0.3-1.1-0.8-1.4-1.4c-0.3-0.6-0.5-1.2-0.5-2
						c0-0.7,0.2-1.4,0.5-1.9c0.3-0.6,0.8-1,1.3-1.4c0.6-0.3,1.2-0.5,1.9-0.5c0.7,0,1.3,0.2,1.9,0.5s1,0.8,1.3,1.4
						c0.3,0.6,0.5,1.2,0.5,2C154.6,189.5,154.6,189.6,154.6,189.8z M149.4,187.3c-0.4,0.4-0.7,0.9-0.8,1.5h4.8
						c-0.1-0.6-0.3-1.1-0.8-1.5c-0.4-0.4-1-0.6-1.6-0.6S149.8,186.9,149.4,187.3z"
                        />
                        <path
                          className="st3"
                          d="M169.1,186.4c0.5,0.5,0.8,1.3,0.8,2.4v4.3h-1.3v-4.1c0-0.7-0.2-1.3-0.5-1.6c-0.3-0.4-0.8-0.5-1.4-0.5
						c-0.7,0-1.2,0.2-1.6,0.6c-0.4,0.4-0.6,1-0.6,1.8v3.8h-1.3v-4.1c0-0.7-0.2-1.3-0.5-1.6c-0.3-0.4-0.8-0.5-1.4-0.5
						c-0.7,0-1.2,0.2-1.6,0.6c-0.4,0.4-0.6,1-0.6,1.8v3.8h-1.3v-7.4h1.3v1.1c0.3-0.4,0.6-0.7,1.1-0.9c0.4-0.2,0.9-0.3,1.5-0.3
						c0.6,0,1.1,0.1,1.5,0.4c0.4,0.2,0.8,0.6,1,1c0.3-0.4,0.7-0.8,1.2-1c0.5-0.2,1.1-0.4,1.7-0.4
						C167.8,185.6,168.6,185.8,169.1,186.4z"
                        />
                        <path
                          className="st3"
                          d="M180.3,189.8h-6c0.1,0.7,0.4,1.2,0.9,1.6c0.5,0.4,1.1,0.6,1.8,0.6c0.9,0,1.6-0.3,2.1-0.9l0.7,0.9
						c-0.3,0.4-0.8,0.7-1.3,0.9c-0.5,0.2-1.1,0.3-1.7,0.3c-0.8,0-1.5-0.2-2.1-0.5c-0.6-0.3-1.1-0.8-1.4-1.4c-0.3-0.6-0.5-1.2-0.5-2
						c0-0.7,0.2-1.4,0.5-1.9c0.3-0.6,0.8-1,1.3-1.4c0.6-0.3,1.2-0.5,1.9-0.5c0.7,0,1.3,0.2,1.9,0.5s1,0.8,1.3,1.4
						c0.3,0.6,0.5,1.2,0.5,2C180.3,189.5,180.3,189.6,180.3,189.8z M175,187.3c-0.4,0.4-0.7,0.9-0.8,1.5h4.8
						c-0.1-0.6-0.3-1.1-0.8-1.5c-0.4-0.4-1-0.6-1.6-0.6S175.4,186.9,175,187.3z"
                        />
                        <path
                          className="st3"
                          d="M189.5,186.4c0.6,0.5,0.8,1.3,0.8,2.4v4.3H189v-4.1c0-0.7-0.2-1.3-0.5-1.6c-0.3-0.4-0.8-0.5-1.5-0.5
						c-0.7,0-1.3,0.2-1.7,0.6c-0.4,0.4-0.6,1-0.6,1.8v3.8h-1.3v-7.4h1.3v1.1c0.3-0.4,0.6-0.7,1.1-0.9s1-0.3,1.6-0.3
						C188.2,185.6,188.9,185.8,189.5,186.4z"
                        />
                        <path
                          className="st3"
                          d="M198.1,192.6c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.6,0.1-0.9,0.1c-0.7,0-1.3-0.2-1.7-0.6s-0.6-1-0.6-1.7
						v-4.1h-1.3v-1.1h1.3V184h1.3v1.6h2.1v1.1h-2.1v4c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.9,0.3c0.4,0,0.8-0.1,1-0.4
						L198.1,192.6z"
                        />
                      </g>
                    </g>
                    <g className="st2">
                      <path
                        className="st3"
                        d="M111,112.1H73.3l-8.6,18.6h-2L91.3,69h1.8l28.6,61.6h-2L111,112.1z M110.2,110.4l-18-39l-18,39H110.2z"
                      />
                      <path
                        className="st3"
                        d="M165.9,87.8c3.4,2,6,4.7,8,8.2s2.9,7.5,2.9,11.9c0,4.4-1,8.3-2.9,11.8c-1.9,3.5-4.6,6.2-8,8.2
					c-3.4,2-7.1,2.9-11.2,2.9c-4.7,0-8.9-1.3-12.5-3.8c-3.7-2.6-6.3-6-7.8-10.3v30.9h-1.8V85.2h1.8v13.9c1.5-4.3,4.1-7.8,7.7-10.3
					c3.6-2.6,7.8-3.8,12.6-3.8C158.8,84.9,162.5,85.9,165.9,87.8z M165,126.6c3.1-1.8,5.5-4.3,7.3-7.6s2.6-6.9,2.6-11
					c0-4.1-0.9-7.8-2.6-11s-4.2-5.8-7.3-7.6c-3.1-1.8-6.6-2.7-10.4-2.7c-3.8,0-7.3,0.9-10.4,2.7c-3.1,1.8-5.5,4.4-7.3,7.6
					c-1.8,3.3-2.6,6.9-2.6,11c0,4.1,0.9,7.8,2.6,11c1.8,3.3,4.2,5.8,7.3,7.6c3.1,1.8,6.6,2.7,10.4,2.7
					C158.5,129.2,161.9,128.3,165,126.6z"
                      />
                    </g>
                    <line id="Ligne_16" className="st4" x1="97.2" y1="160.1" x2="142.2" y2="160.1" />
                  </g>
                </DnaAtom>
              </g>
              <g id="ADN-Px">
                <line id="Ligne_12" className="st5" x1="69.6" y1="598.8" x2="171.6" y2="475.8" />
                <line id="Ligne_13" className="st5" x1="44.6" y1="353.8" x2="171.6" y2="480.8" />
                <DnaAtom {...values.find((o) => o.name.toLowerCase() === 'proximité')}>
                  <g id="PROXIMITE" transform="translate(75.738 397.142)">
                    <circle id="circle-proximite" className="st1" cx="88.8" cy="78.6" r="65.5" />
                    <g id="Legend-2" transform="translate(-34.852 -76.917)">
                      <g className="st2">
                        <path
                          className="st3"
                          d="M93.2,184.2c0.7,0.6,1.1,1.4,1.1,2.5c0,1.1-0.4,1.9-1.1,2.5c-0.7,0.6-1.7,0.9-3,0.9h-2.4v3h-1.4v-9.8h3.8
						C91.5,183.3,92.5,183.6,93.2,184.2z M92.2,188.3c0.5-0.4,0.7-0.9,0.7-1.6s-0.2-1.3-0.7-1.6s-1.1-0.6-2-0.6h-2.4v4.4h2.4
						C91.1,188.9,91.8,188.7,92.2,188.3z"
                        />
                        <path
                          className="st3"
                          d="M99.5,185.9c0.5-0.2,1-0.3,1.7-0.3v1.3c-0.1,0-0.2,0-0.3,0c-0.7,0-1.3,0.2-1.7,0.7
						c-0.4,0.4-0.6,1.1-0.6,1.9v3.7h-1.3v-7.4h1.3v1.2C98.7,186.5,99.1,186.1,99.5,185.9z"
                        />
                        <path
                          className="st3"
                          d="M105.1,192.6c-0.6-0.3-1-0.8-1.4-1.4s-0.5-1.2-0.5-2c0-0.7,0.2-1.4,0.5-2s0.8-1,1.4-1.3s1.3-0.5,2-0.5
						s1.4,0.2,2,0.5c0.6,0.3,1,0.8,1.4,1.3c0.3,0.6,0.5,1.2,0.5,2c0,0.7-0.2,1.4-0.5,2c-0.3,0.6-0.8,1-1.4,1.4
						c-0.6,0.3-1.2,0.5-2,0.5S105.7,193,105.1,192.6z M108.3,191.6c0.4-0.2,0.7-0.5,0.9-0.9c0.2-0.4,0.3-0.9,0.3-1.4s-0.1-1-0.3-1.4
						c-0.2-0.4-0.5-0.7-0.9-0.9c-0.4-0.2-0.8-0.3-1.3-0.3s-0.9,0.1-1.3,0.3s-0.7,0.5-0.9,0.9c-0.2,0.4-0.3,0.9-0.3,1.4
						s0.1,1,0.3,1.4c0.2,0.4,0.5,0.7,0.9,0.9s0.8,0.3,1.3,0.3S108,191.9,108.3,191.6z"
                        />
                        <path
                          className="st3"
                          d="M118.4,193.1l-2.1-2.8l-2.2,2.8h-1.5l2.9-3.8l-2.8-3.6h1.5l2,2.7l2-2.7h1.5l-2.8,3.6l3,3.8H118.4z"
                        />
                        <path
                          className="st3"
                          d="M122.5,184c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.2,0.1-0.5,0.3-0.6c0.2-0.2,0.4-0.3,0.7-0.3s0.5,0.1,0.7,0.2
						c0.2,0.2,0.3,0.4,0.3,0.6c0,0.3-0.1,0.5-0.3,0.6c-0.2,0.2-0.4,0.3-0.7,0.3S122.7,184.1,122.5,184z M122.5,185.6h1.3v7.4h-1.3
						V185.6z"
                        />
                        <path
                          className="st3"
                          d="M139,186.4c0.5,0.5,0.8,1.3,0.8,2.4v4.3h-1.3v-4.1c0-0.7-0.2-1.3-0.5-1.6c-0.3-0.4-0.8-0.5-1.4-0.5
						c-0.7,0-1.2,0.2-1.6,0.6c-0.4,0.4-0.6,1-0.6,1.8v3.8H133v-4.1c0-0.7-0.2-1.3-0.5-1.6c-0.3-0.4-0.8-0.5-1.4-0.5
						c-0.7,0-1.2,0.2-1.6,0.6c-0.4,0.4-0.6,1-0.6,1.8v3.8h-1.3v-7.4h1.3v1.1c0.3-0.4,0.6-0.7,1.1-0.9c0.4-0.2,0.9-0.3,1.5-0.3
						c0.6,0,1.1,0.1,1.5,0.4s0.8,0.6,1,1c0.3-0.4,0.7-0.8,1.2-1c0.5-0.2,1.1-0.4,1.7-0.4C137.7,185.6,138.5,185.8,139,186.4z"
                        />
                        <path
                          className="st3"
                          d="M143.4,184c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.2,0.1-0.5,0.3-0.6s0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.2
						c0.2,0.2,0.3,0.4,0.3,0.6c0,0.3-0.1,0.5-0.3,0.6c-0.2,0.2-0.4,0.3-0.7,0.3C143.8,184.2,143.6,184.1,143.4,184z M143.4,185.6
						h1.3v7.4h-1.3V185.6z"
                        />
                        <path
                          className="st3"
                          d="M152.6,192.6c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.6,0.1-0.9,0.1c-0.7,0-1.3-0.2-1.7-0.6
						c-0.4-0.4-0.6-1-0.6-1.7v-4.1h-1.3v-1.1h1.3V184h1.3v1.6h2.1v1.1H150v4c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.9,0.3
						c0.4,0,0.8-0.1,1-0.4L152.6,192.6z"
                        />
                        <path
                          className="st3"
                          d="M161.8,189.8h-6c0.1,0.7,0.4,1.2,0.9,1.6c0.5,0.4,1.1,0.6,1.8,0.6c0.9,0,1.6-0.3,2.1-0.9l0.7,0.9
						c-0.3,0.4-0.8,0.7-1.3,0.9c-0.5,0.2-1.1,0.3-1.7,0.3c-0.8,0-1.5-0.2-2.1-0.5c-0.6-0.3-1.1-0.8-1.4-1.4c-0.3-0.6-0.5-1.2-0.5-2
						c0-0.7,0.2-1.4,0.5-1.9c0.3-0.6,0.8-1,1.3-1.4s1.2-0.5,1.9-0.5s1.3,0.2,1.9,0.5s1,0.8,1.3,1.4c0.3,0.6,0.5,1.2,0.5,2
						C161.8,189.5,161.8,189.6,161.8,189.8z M156.5,187.3c-0.4,0.4-0.7,0.9-0.8,1.5h4.8c-0.1-0.6-0.3-1.1-0.8-1.5s-1-0.6-1.6-0.6
						C157.5,186.7,157,186.9,156.5,187.3z M159,182.7h1.7l-2.4,1.8h-1.3L159,182.7z"
                        />
                      </g>
                    </g>
                    <g className="st2">
                      <path
                        className="st3"
                        d="M85.2,46.8c2.9,2.4,4.4,5.8,4.4,10.2c0,4.4-1.5,7.8-4.4,10.2c-2.9,2.4-7,3.6-12.1,3.6h-13v14.4h-1.2v-42
					h14.2C78.3,43.1,82.3,44.3,85.2,46.8z M84.5,66.3c2.6-2.2,3.9-5.3,3.9-9.4c0-4-1.3-7.2-3.9-9.4c-2.6-2.2-6.4-3.3-11.2-3.3H60.1
					v25.3h13.1C78.1,69.6,81.9,68.5,84.5,66.3z"
                      />
                      <path
                        className="st3"
                        d="M118.1,85.1L106.4,70L94.6,85.1h-1.5l12.5-16.1L93.8,54.1h1.5l11,14.1l11-14.1h1.4L107,69.1l12.6,16.1
					H118.1z"
                      />
                    </g>
                    <line id="Ligne_16-2" className="st4" x1="66.8" y1="97.6" x2="111.8" y2="97.6" />
                  </g>
                </DnaAtom>
              </g>
              <g id="ADN-Hu">
                <ellipse id="Ellipse_69" className="st1" cx="-81.9" cy="95.3" rx="63" ry="62" />
                <path id="Tracé_544" className="st5" d="M23.3,298l-97-197.3" />
                <line id="Ligne_11" className="st5" x1="212.6" y1="189.8" x2="24.6" y2="294.8" />
                <circle id="Ellipse_71" className="st1" cx="212.1" cy="189.3" r="40" />
                <DnaAtom {...values.find((o) => o.name.toLowerCase() === 'humain')}>
                  <g id="HUMAIN" transform="translate(-99.615 176.226)">
                    <ellipse id="circle-humain" className="st1" cx="123.7" cy="123.6" rx="111" ry="110.5" />
                    <g id="Legend-3" transform="translate(0 -2)">
                      <g className="st2">
                        <path className="st3" d="M99.8,183.3v9.8h-1.4v-4.4h-5.6v4.4h-1.4v-9.8h1.4v4.2h5.6v-4.2H99.8z" />
                        <path
                          className="st3"
                          d="M110.6,185.6v7.4h-1.3v-1.1c-0.3,0.4-0.6,0.7-1.1,0.9c-0.4,0.2-0.9,0.3-1.4,0.3c-1,0-1.8-0.3-2.3-0.8
						c-0.6-0.5-0.9-1.4-0.9-2.4v-4.3h1.3v4.1c0,0.7,0.2,1.3,0.5,1.6s0.8,0.6,1.5,0.6c0.7,0,1.3-0.2,1.7-0.6c0.4-0.4,0.6-1,0.6-1.8
						v-3.8H110.6z"
                        />
                        <path
                          className="st3"
                          d="M125.8,186.4c0.5,0.5,0.8,1.3,0.8,2.4v4.3h-1.3v-4.1c0-0.7-0.2-1.3-0.5-1.6c-0.3-0.4-0.8-0.5-1.4-0.5
						c-0.7,0-1.2,0.2-1.6,0.6c-0.4,0.4-0.6,1-0.6,1.8v3.8h-1.3v-4.1c0-0.7-0.2-1.3-0.5-1.6c-0.3-0.4-0.8-0.5-1.4-0.5
						c-0.7,0-1.2,0.2-1.6,0.6c-0.4,0.4-0.6,1-0.6,1.8v3.8h-1.3v-7.4h1.3v1.1c0.3-0.4,0.6-0.7,1.1-0.9c0.4-0.2,0.9-0.3,1.5-0.3
						c0.6,0,1.1,0.1,1.5,0.4c0.4,0.2,0.8,0.6,1,1c0.3-0.4,0.7-0.8,1.2-1c0.5-0.2,1.1-0.4,1.7-0.4
						C124.5,185.6,125.2,185.8,125.8,186.4z"
                        />
                        <path
                          className="st3"
                          d="M135.3,186.3c0.5,0.5,0.8,1.3,0.8,2.3v4.5h-1.3v-1c-0.2,0.3-0.5,0.6-1,0.8c-0.4,0.2-0.9,0.3-1.5,0.3
						c-0.8,0-1.5-0.2-2-0.6c-0.5-0.4-0.7-0.9-0.7-1.6s0.2-1.2,0.7-1.6s1.2-0.6,2.3-0.6h2.1v-0.3c0-0.6-0.2-1-0.5-1.3
						c-0.3-0.3-0.8-0.5-1.5-0.5c-0.4,0-0.9,0.1-1.3,0.2c-0.4,0.1-0.8,0.3-1.1,0.6l-0.6-1c0.4-0.3,0.8-0.5,1.4-0.7
						c0.5-0.2,1.1-0.2,1.7-0.2C133.9,185.6,134.7,185.8,135.3,186.3z M133.9,191.8c0.4-0.2,0.6-0.6,0.8-1v-1h-2.1
						c-1.1,0-1.7,0.4-1.7,1.1c0,0.4,0.1,0.7,0.4,0.9c0.3,0.2,0.7,0.3,1.2,0.3S133.5,192,133.9,191.8z"
                        />
                        <path
                          className="st3"
                          d="M139.7,184c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.2,0.1-0.5,0.3-0.6s0.4-0.3,0.7-0.3s0.5,0.1,0.7,0.2
						c0.2,0.2,0.3,0.4,0.3,0.6c0,0.3-0.1,0.5-0.3,0.6c-0.2,0.2-0.4,0.3-0.7,0.3S139.9,184.1,139.7,184z M139.7,185.6h1.3v7.4h-1.3
						V185.6z"
                        />
                        <path
                          className="st3"
                          d="M150.9,186.4c0.6,0.5,0.8,1.3,0.8,2.4v4.3h-1.3v-4.1c0-0.7-0.2-1.3-0.5-1.6c-0.3-0.4-0.8-0.5-1.5-0.5
						c-0.7,0-1.3,0.2-1.7,0.6c-0.4,0.4-0.6,1-0.6,1.8v3.8h-1.3v-7.4h1.3v1.1c0.3-0.4,0.6-0.7,1.1-0.9s1-0.3,1.6-0.3
						C149.6,185.6,150.4,185.8,150.9,186.4z"
                        />
                      </g>
                    </g>
                    <g className="st2">
                      <path
                        className="st3"
                        d="M115.8,84.5v61.6H114v-30.4H69.1v30.4h-1.8V84.5h1.8v29.5H114V84.5H115.8z"
                      />
                      <path
                        className="st3"
                        d="M175.8,100.6v45.5H174V134c-1.4,3.9-3.6,6.9-6.8,9.1c-3.2,2.2-7,3.3-11.5,3.3c-5.9,0-10.4-1.6-13.7-4.9
					c-3.3-3.3-4.9-7.9-4.9-14v-26.8h1.8v26.8c0,5.6,1.5,9.8,4.4,12.8c2.9,3,7.1,4.4,12.4,4.4c5.7,0,10.2-1.8,13.5-5.3
					c3.3-3.5,4.9-8.2,4.9-14v-24.7H175.8z"
                      />
                    </g>
                    <line id="Ligne_16-3" className="st4" x1="99.1" y1="172.6" x2="144.1" y2="172.6" />
                  </g>
                </DnaAtom>
              </g>
            </g>
          </svg>
        </MobileDna>
        <DesktopDna>
          <svg
            css={css`
              height: 100%;
              width: 100%;

              a {
                outline: none;
                cursor: pointer;
              }

              a:hover .st0,
              a:focus .st0 {
                stroke-width: 30px;
              }

              .st0 {
                fill: #62318a;
                stroke-width: 0;
                stroke: #62318a;
                stroke-opacity: 0.2;
                transition: stroke-width 0.3s ease-in-out;
              }
              #circle-humain {
                animation: ${breathing(169.5, 180)} 5s ease infinite;
              }
              #circle-proximite {
                animation: ${breathing(100, 120)} 5s ease infinite;
              }
              #circle-accompagnement {
                animation: ${breathing(116, 130)} 5s ease infinite;
              }
              .st1 {
                fill: none;
                stroke: #62318a;
                stroke-width: 10;
              }
              .st2 {
                opacity: 0.2;
                fill: #62318a;
                enable-background: new;
              }
              .st3 {
                enable-background: new;
              }
              .st4 {
                fill: #ffffff;
              }
              .st5 {
                fill: none;
                stroke: #d7cbe4;
              }
            `}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1248 836"
            xmlSpace="preserve"
          >
            <circle id="Ellipse_69" className="st0" cx="215" cy="54" r="54" />
            <line id="Ligne_14" className="st1" x1="754" y1="270.5" x2="1114" y2="550.5" />
            <line id="Ligne_11" className="st1" x1="218" y1="54" x2="439" y2="431" />
            <line id="Ligne_12" className="st1" x1="438.5" y1="430.5" x2="754.5" y2="271.5" />
            <line id="Ligne_13" className="st1" x1="438.5" y1="430.5" x2="147.5" y2="670.5" />
            <circle id="Ellipse_71" className="st0" cx="109" cy="727" r="109" />
            <DnaAtom {...values.find((o) => o.name.toLowerCase() === 'humain')}>
              <g id="HUMAIN" transform="translate(247 259)">
                <circle id="circle-humain" className="st0" cx="163.4" cy="169.5" r="169.5" />
                <g>
                  <g id="Legend-3" transform="translate(0 -2)">
                    <g className="st3">
                      <path
                        className="st4"
                        d="M130.7,243.2v14.7h-2.1v-6.6h-8.4v6.6h-2.1v-14.7h2.1v6.3h8.4v-6.3H130.7z"
                      />
                      <path
                        className="st4"
                        d="M146.9,246.6v11.1h-2v-1.7c-0.4,0.6-0.9,1-1.6,1.4c-0.6,0.3-1.4,0.4-2.1,0.4c-1.5,0-2.7-0.4-3.4-1.2
					c-0.9-0.8-1.4-2.1-1.4-3.6v-6.4h2v6.2c0,1,0.3,1.9,0.8,2.4c0.4,0.4,1.2,0.9,2.2,0.9c1,0,1.9-0.3,2.5-0.9
					c0.6-0.6,0.9-1.5,0.9-2.7v-5.7h2.1V246.6z"
                      />
                      <path
                        className="st4"
                        d="M169.7,247.8c0.8,0.8,1.2,2,1.2,3.6v6.4h-2v-6.1c0-1.1-0.3-2-0.8-2.4c-0.4-0.6-1.2-0.8-2.1-0.8
					c-1,0-1.8,0.3-2.4,0.9c-0.6,0.6-0.9,1.5-0.9,2.7v5.7h-2v-6.1c0-1.1-0.3-2-0.8-2.4c-0.4-0.6-1.2-0.8-2.1-0.8
					c-1.1,0-1.8,0.3-2.4,0.9c-0.6,0.6-0.9,1.5-0.9,2.7v5.7h-1.9v-11.1h1.9v1.7c0.5-0.6,0.9-1.1,1.6-1.3c0.6-0.3,1.4-0.5,2.2-0.5
					c0.9,0,1.7,0.2,2.2,0.6c0.6,0.3,1.2,0.9,1.5,1.5c0.5-0.6,1.1-1.2,1.8-1.5c0.8-0.3,1.6-0.6,2.5-0.6
					C167.8,246.6,168.8,246.9,169.7,247.8z"
                      />
                      <path
                        className="st4"
                        d="M184,247.7c0.8,0.8,1.2,1.9,1.2,3.4v6.8h-2v-1.5c-0.3,0.5-0.8,0.9-1.5,1.2c-0.6,0.3-1.3,0.5-2.2,0.5
					c-1.2,0-2.2-0.3-3-0.9c-0.8-0.6-1-1.3-1-2.4s0.3-1.8,1-2.4c0.8-0.6,1.8-0.9,3.5-0.9h3.2V251c0-0.9-0.3-1.5-0.8-2
					c-0.5-0.4-1.2-0.8-2.2-0.8c-0.6,0-1.3,0.2-2,0.3c-0.6,0.2-1.2,0.4-1.7,0.9l-0.9-1.5c0.6-0.5,1.2-0.8,2.1-1.1
					c0.8-0.3,1.7-0.3,2.6-0.3C181.9,246.6,183.1,246.9,184,247.7z M181.9,255.9c0.6-0.3,0.9-0.9,1.2-1.5v-1.5h-3.2
					c-1.7,0-2.5,0.6-2.5,1.7c0,0.6,0.2,1,0.6,1.3c0.4,0.3,1,0.4,1.8,0.4S181.3,256.2,181.9,255.9z"
                      />
                      <path
                        className="st4"
                        d="M190.6,244.2c-0.3-0.3-0.4-0.6-0.4-0.9c0-0.3,0.2-0.8,0.4-0.9c0.3-0.2,0.6-0.4,1.1-0.4c0.4,0,0.8,0.2,1,0.3
					c0.3,0.3,0.5,0.6,0.5,0.9c0,0.5-0.2,0.8-0.5,0.9c-0.3,0.3-0.6,0.5-1,0.5C191.2,244.5,190.9,244.4,190.6,244.2z M190.6,246.6h2
					v11.1h-2V246.6z"
                      />
                      <path
                        className="st4"
                        d="M207.4,247.8c0.9,0.8,1.2,2,1.2,3.6v6.4h-1.9v-6.1c0-1.1-0.3-2-0.8-2.4c-0.5-0.6-1.2-0.8-2.2-0.8
					c-1.1,0-2,0.3-2.6,0.9c-0.6,0.6-0.9,1.5-0.9,2.7v5.7h-1.9v-11.1h1.9v1.7c0.5-0.6,0.9-1.1,1.7-1.3c0.8-0.3,1.5-0.5,2.4-0.5
					C205.4,246.6,206.6,246.9,207.4,247.8z"
                      />
                    </g>
                  </g>
                  <g className="st3">
                    <path className="st4" d="M154.7,96v92.4H152v-45.6H84.7v45.6H82V96h2.7v44.2H152V96H154.7z" />
                    <path
                      className="st4"
                      d="M244.7,120.1v68.3H242v-18.2c-2.1,5.8-5.4,10.3-10.2,13.7c-4.8,3.3-10.5,5-17.2,5c-8.8,0-15.6-2.4-20.5-7.3
				c-5-5-7.3-11.8-7.3-21v-40.2h2.7v40.2c0,8.4,2.2,14.7,6.6,19.2s10.7,6.6,18.6,6.6c8.6,0,15.3-2.7,20.2-7.9c5-5.2,7.3-12.3,7.3-21
				v-37.1h2.6V120.1z"
                    />
                  </g>
                  <line id="Ligne_16-3" className="st5" x1="129.7" y1="228.1" x2="197.2" y2="228.1" />
                </g>
              </g>
            </DnaAtom>
            <DnaAtom {...values.find((o) => o.name.toLowerCase() === 'accompagnement')}>
              <g id="ACCOMPAGNEMENT" transform="translate(982 454)">
                <circle id="circle-accompagnement" className="st0" cx="116" cy="116" r="116" />
                <g>
                  <g id="Legend" transform="translate(-4.235 -14.409)">
                    <g className="st3">
                      <path
                        className="st4"
                        d="M53.8,181.4h-5.2l-1.1,2.5h-1.4l4.4-9.8h1.4l4.5,9.8h-1.5L53.8,181.4z M53.3,180.3l-2.1-4.8l-2.1,4.8H53.3z
					"
                      />
                      <path
                        className="st4"
                        d="M59.9,183.4c-0.6-0.3-1.1-0.8-1.4-1.4c-0.3-0.6-0.5-1.2-0.5-2c0-0.7,0.2-1.4,0.5-2s0.8-1,1.4-1.3
					c0.6-0.3,1.3-0.5,2-0.5s1.3,0.1,1.8,0.4c0.5,0.3,0.9,0.7,1.2,1.2l-1,0.7c-0.2-0.4-0.5-0.6-0.9-0.8c-0.3-0.2-0.7-0.3-1.1-0.3
					c-0.5,0-0.9,0.1-1.3,0.3s-0.7,0.5-0.9,0.9s-0.3,0.9-0.3,1.4s0.1,1,0.3,1.4s0.5,0.7,0.9,0.9s0.8,0.3,1.3,0.3
					c0.4,0,0.8-0.1,1.1-0.3c0.3-0.2,0.6-0.4,0.9-0.8l1,0.6c-0.3,0.5-0.7,0.9-1.2,1.2s-1.1,0.4-1.8,0.4
					C61.2,183.9,60.5,183.8,59.9,183.4z"
                      />
                      <path
                        className="st4"
                        d="M68.9,183.4c-0.6-0.3-1.1-0.8-1.4-1.4c-0.3-0.6-0.5-1.2-0.5-2c0-0.7,0.2-1.4,0.5-2s0.8-1,1.4-1.3
					s1.3-0.5,2-0.5s1.3,0.1,1.8,0.4c0.5,0.3,0.9,0.7,1.2,1.2l-1,0.7c-0.2-0.4-0.5-0.6-0.9-0.8c-0.3-0.2-0.7-0.3-1.1-0.3
					c-0.5,0-0.9,0.1-1.3,0.3s-0.7,0.5-0.9,0.9c-0.2,0.4-0.3,0.9-0.3,1.4s0.1,1,0.3,1.4s0.5,0.7,0.9,0.9s0.8,0.3,1.3,0.3
					c0.4,0,0.8-0.1,1.1-0.3c0.3-0.2,0.6-0.4,0.9-0.8l1,0.6c-0.3,0.5-0.7,0.9-1.2,1.2s-1.1,0.4-1.8,0.4
					C70.2,183.9,69.5,183.8,68.9,183.4z"
                      />
                      <path
                        className="st4"
                        d="M77.9,183.4c-0.6-0.3-1-0.8-1.4-1.4c-0.3-0.6-0.5-1.2-0.5-2c0-0.7,0.2-1.4,0.5-2s0.8-1,1.4-1.3
					s1.3-0.5,2-0.5s1.4,0.2,2,0.5s1,0.8,1.4,1.3c0.3,0.6,0.5,1.2,0.5,2c0,0.7-0.2,1.4-0.5,2s-0.8,1-1.4,1.4c-0.6,0.3-1.2,0.5-2,0.5
					C79.1,183.9,78.5,183.8,77.9,183.4z M81.2,182.4c0.4-0.2,0.7-0.5,0.9-0.9c0.2-0.4,0.3-0.9,0.3-1.4s-0.1-1-0.3-1.4
					c-0.2-0.4-0.5-0.7-0.9-0.9s-0.8-0.3-1.3-0.3s-0.9,0.1-1.3,0.3s-0.7,0.5-0.9,0.9c-0.2,0.4-0.3,0.9-0.3,1.4s0.1,1,0.3,1.4
					s0.5,0.7,0.9,0.9s0.8,0.3,1.3,0.3S80.8,182.7,81.2,182.4z"
                      />
                      <path
                        className="st4"
                        d="M98.2,177.2c0.5,0.5,0.8,1.3,0.8,2.4v4.3h-1.3v-4.1c0-0.7-0.2-1.3-0.5-1.6c-0.3-0.4-0.8-0.5-1.4-0.5
					c-0.7,0-1.2,0.2-1.6,0.6c-0.4,0.4-0.6,1-0.6,1.8v3.8h-1.3v-4.1c0-0.7-0.2-1.3-0.5-1.6c-0.3-0.4-0.8-0.5-1.4-0.5
					c-0.7,0-1.2,0.2-1.6,0.6c-0.4,0.4-0.6,1-0.6,1.8v3.8h-1.3v-7.4h1.3v1.1c0.3-0.4,0.6-0.7,1.1-0.9c0.4-0.2,0.9-0.3,1.5-0.3
					s1.1,0.1,1.5,0.4c0.4,0.2,0.8,0.6,1,1c0.3-0.4,0.7-0.8,1.2-1s1.1-0.4,1.7-0.4C96.9,176.4,97.7,176.6,98.2,177.2z"
                      />
                      <path
                        className="st4"
                        d="M108.5,176.8c0.6,0.3,1,0.8,1.3,1.3c0.3,0.6,0.5,1.2,0.5,2s-0.2,1.4-0.5,2s-0.8,1-1.3,1.3
					c-0.6,0.3-1.2,0.5-1.9,0.5c-0.5,0-1-0.1-1.5-0.3c-0.4-0.2-0.8-0.5-1.1-0.9v3.8h-1.3v-10.1h1.3v1.2c0.3-0.4,0.7-0.7,1.1-0.9
					c0.5-0.2,1-0.3,1.5-0.3C107.3,176.4,107.9,176.5,108.5,176.8z M107.7,182.4c0.4-0.2,0.7-0.5,0.9-0.9s0.3-0.9,0.3-1.4
					s-0.1-1-0.3-1.4c-0.2-0.4-0.5-0.7-0.9-0.9s-0.8-0.3-1.3-0.3s-0.9,0.1-1.3,0.3s-0.7,0.5-0.9,0.9c-0.2,0.4-0.3,0.8-0.3,1.4
					s0.1,1,0.3,1.4c0.2,0.4,0.5,0.7,0.9,0.9s0.8,0.3,1.3,0.3C106.9,182.8,107.3,182.7,107.7,182.4z"
                      />
                      <path
                        className="st4"
                        d="M118.3,177.1c0.5,0.5,0.8,1.3,0.8,2.3v4.5h-1.3v-1c-0.2,0.3-0.5,0.6-1,0.8c-0.4,0.2-0.9,0.3-1.5,0.3
					c-0.8,0-1.5-0.2-2-0.6c-0.5-0.4-0.7-0.9-0.7-1.6s0.2-1.2,0.7-1.6s1.2-0.6,2.3-0.6h2.1v-0.3c0-0.6-0.2-1-0.5-1.3
					s-0.8-0.5-1.5-0.5c-0.4,0-0.9,0.1-1.3,0.2c-0.4,0.1-0.8,0.3-1.1,0.6l-0.6-1c0.4-0.3,0.8-0.5,1.4-0.7c0.5-0.2,1.1-0.2,1.7-0.2
					C117,176.4,117.7,176.6,118.3,177.1z M116.9,182.6c0.4-0.2,0.6-0.6,0.8-1v-1h-2.1c-1.1,0-1.7,0.4-1.7,1.1c0,0.4,0.1,0.7,0.4,0.9
					s0.7,0.3,1.2,0.3S116.6,182.8,116.9,182.6z"
                      />
                      <path
                        className="st4"
                        d="M129.9,176.4v6.4c0,1.3-0.3,2.3-1,2.9c-0.6,0.6-1.6,0.9-2.9,0.9c-0.7,0-1.4-0.1-2-0.3s-1.1-0.5-1.5-0.8
					l0.6-1c0.3,0.3,0.8,0.5,1.3,0.7c0.5,0.2,1,0.3,1.6,0.3c0.9,0,1.5-0.2,1.9-0.6c0.4-0.4,0.6-1,0.6-1.9v-0.6
					c-0.3,0.4-0.7,0.7-1.2,0.9s-1,0.3-1.5,0.3c-0.7,0-1.4-0.2-1.9-0.5c-0.6-0.3-1-0.7-1.4-1.3c-0.3-0.5-0.5-1.2-0.5-1.9
					s0.2-1.3,0.5-1.9c0.3-0.5,0.8-1,1.4-1.3s1.2-0.4,1.9-0.4c0.6,0,1.1,0.1,1.6,0.3s0.9,0.5,1.2,0.9v-1.1L129.9,176.4L129.9,176.4z
					 M127.3,182.1c0.4-0.2,0.7-0.5,0.9-0.9c0.2-0.4,0.3-0.8,0.3-1.3c0-0.7-0.2-1.3-0.7-1.7c-0.5-0.4-1.1-0.7-1.8-0.7
					c-0.8,0-1.4,0.2-1.8,0.7c-0.5,0.4-0.7,1-0.7,1.7c0,0.5,0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.7,0.9,0.9s0.8,0.3,1.3,0.3
					S126.9,182.3,127.3,182.1z"
                      />
                      <path
                        className="st4"
                        d="M139.7,177.2c0.6,0.5,0.8,1.3,0.8,2.4v4.3h-1.3v-4.1c0-0.7-0.2-1.3-0.5-1.6c-0.3-0.4-0.8-0.5-1.5-0.5
					s-1.3,0.2-1.7,0.6c-0.4,0.4-0.6,1-0.6,1.8v3.8h-1.3v-7.4h1.3v1.1c0.3-0.4,0.6-0.7,1.1-0.9s1-0.3,1.6-0.3
					C138.4,176.4,139.2,176.6,139.7,177.2z"
                      />
                      <path
                        className="st4"
                        d="M150.9,180.6h-6c0.1,0.7,0.4,1.2,0.9,1.6c0.5,0.4,1.1,0.6,1.8,0.6c0.9,0,1.6-0.3,2.1-0.9l0.7,0.9
					c-0.3,0.4-0.8,0.7-1.3,0.9s-1.1,0.3-1.7,0.3c-0.8,0-1.5-0.2-2.1-0.5s-1.1-0.8-1.4-1.4c-0.3-0.6-0.5-1.2-0.5-2
					c0-0.7,0.2-1.4,0.5-1.9c0.3-0.6,0.8-1,1.3-1.4c0.6-0.3,1.2-0.5,1.9-0.5s1.3,0.2,1.9,0.5s1,0.8,1.3,1.4c0.3,0.6,0.5,1.2,0.5,2
					C150.9,180.3,150.9,180.4,150.9,180.6z M145.7,178.1c-0.4,0.4-0.7,0.9-0.8,1.5h4.8c-0.1-0.6-0.3-1.1-0.8-1.5
					c-0.4-0.4-1-0.6-1.6-0.6S146.1,177.7,145.7,178.1z"
                      />
                      <path
                        className="st4"
                        d="M165.4,177.2c0.5,0.5,0.8,1.3,0.8,2.4v4.3h-1.3v-4.1c0-0.7-0.2-1.3-0.5-1.6c-0.3-0.4-0.8-0.5-1.4-0.5
					c-0.7,0-1.2,0.2-1.6,0.6c-0.4,0.4-0.6,1-0.6,1.8v3.8h-1.3v-4.1c0-0.7-0.2-1.3-0.5-1.6c-0.3-0.4-0.8-0.5-1.4-0.5
					c-0.7,0-1.2,0.2-1.6,0.6c-0.4,0.4-0.6,1-0.6,1.8v3.8h-1.3v-7.4h1.3v1.1c0.3-0.4,0.6-0.7,1.1-0.9c0.4-0.2,0.9-0.3,1.5-0.3
					s1.1,0.1,1.5,0.4c0.4,0.2,0.8,0.6,1,1c0.3-0.4,0.7-0.8,1.2-1s1.1-0.4,1.7-0.4C164.1,176.4,164.9,176.6,165.4,177.2z"
                      />
                      <path
                        className="st4"
                        d="M176.6,180.6h-6c0.1,0.7,0.4,1.2,0.9,1.6c0.5,0.4,1.1,0.6,1.8,0.6c0.9,0,1.6-0.3,2.1-0.9l0.7,0.9
					c-0.3,0.4-0.8,0.7-1.3,0.9s-1.1,0.3-1.7,0.3c-0.8,0-1.5-0.2-2.1-0.5s-1.1-0.8-1.4-1.4c-0.3-0.6-0.5-1.2-0.5-2
					c0-0.7,0.2-1.4,0.5-1.9c0.3-0.6,0.8-1,1.3-1.4c0.6-0.3,1.2-0.5,1.9-0.5s1.3,0.2,1.9,0.5s1,0.8,1.3,1.4c0.3,0.6,0.5,1.2,0.5,2
					C176.6,180.3,176.6,180.4,176.6,180.6z M171.3,178.1c-0.4,0.4-0.7,0.9-0.8,1.5h4.8c-0.1-0.6-0.3-1.1-0.8-1.5
					c-0.4-0.4-1-0.6-1.6-0.6S171.7,177.7,171.3,178.1z"
                      />
                      <path
                        className="st4"
                        d="M185.8,177.2c0.6,0.5,0.8,1.3,0.8,2.4v4.3h-1.3v-4.1c0-0.7-0.2-1.3-0.5-1.6c-0.3-0.4-0.8-0.5-1.5-0.5
					s-1.3,0.2-1.7,0.6c-0.4,0.4-0.6,1-0.6,1.8v3.8h-1.3v-7.4h1.3v1.1c0.3-0.4,0.6-0.7,1.1-0.9s1-0.3,1.6-0.3
					C184.5,176.4,185.2,176.6,185.8,177.2z"
                      />
                      <path
                        className="st4"
                        d="M194.4,183.4c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.6,0.1-0.9,0.1c-0.7,0-1.3-0.2-1.7-0.6s-0.6-1-0.6-1.7
					v-4.1h-1.3v-1.1h1.3v-1.6h1.3v1.6h2.1v1.1h-2.1v4c0,0.4,0.1,0.7,0.3,0.9s0.5,0.3,0.9,0.3s0.8-0.1,1-0.4L194.4,183.4z"
                      />
                    </g>
                  </g>
                  <g className="st3">
                    <path
                      className="st4"
                      d="M107.3,102.9H69.6L61,121.5h-2l28.6-61.7h1.8l28.6,61.6h-2L107.3,102.9z M106.5,101.2l-18-39l-18,39H106.5z"
                    />
                    <path
                      className="st4"
                      d="M162.2,78.6c3.4,2,6,4.7,8,8.2s2.9,7.5,2.9,11.9s-1,8.3-2.9,11.8s-4.6,6.2-8,8.2c-3.4,2-7.1,2.9-11.2,2.9
				c-4.7,0-8.9-1.3-12.5-3.8c-3.7-2.6-6.3-6-7.8-10.3v30.9h-1.8V76h1.8v13.9c1.5-4.3,4.1-7.8,7.7-10.3c3.6-2.6,7.8-3.8,12.6-3.8
				C155.1,75.7,158.8,76.7,162.2,78.6z M161.3,117.4c3.1-1.8,5.5-4.3,7.3-7.6s2.6-6.9,2.6-11s-0.9-7.8-2.6-11s-4.2-5.8-7.3-7.6
				c-3.1-1.8-6.6-2.7-10.4-2.7c-3.8,0-7.3,0.9-10.4,2.7c-3.1,1.8-5.5,4.4-7.3,7.6c-1.8,3.3-2.6,6.9-2.6,11s0.9,7.8,2.6,11
				c1.8,3.3,4.2,5.8,7.3,7.6s6.6,2.7,10.4,2.7C154.8,120,158.2,119.1,161.3,117.4z"
                    />
                  </g>
                  <line id="Ligne_16" className="st5" x1="93.5" y1="150.9" x2="138.5" y2="150.9" />
                </g>
              </g>
            </DnaAtom>
            <DnaAtom {...values.find((o) => o.name.toLowerCase() === 'proximité')}>
              <g id="PROXIMITE" transform="translate(647 190)">
                <circle id="circle-proximite" className="st0" cx="116" cy="100" r="100" />
                <g>
                  <g id="Legend-2" transform="translate(-34.852 -76.917)">
                    <g className="st3">
                      <path
                        className="st4"
                        d="M104.5,218.2c1,0.9,1.6,2.1,1.6,3.8s-0.6,2.8-1.6,3.8s-2.5,1.3-4.5,1.3h-3.6v4.5h-2.1v-14.7h5.7
					C102,216.9,103.5,217.3,104.5,218.2z M103,224.4c0.8-0.6,1-1.3,1-2.4s-0.3-2-1-2.4c-0.8-0.5-1.7-0.9-3-0.9h-3.6v6.6h3.6
					C101.4,225.3,102.4,225,103,224.4z"
                      />
                      <path
                        className="st4"
                        d="M114,220.8c0.8-0.3,1.5-0.4,2.5-0.4v1.9c-0.2,0-0.3,0-0.5,0c-1,0-2,0.3-2.5,1.1c-0.6,0.6-0.9,1.7-0.9,2.8
					v5.5h-2v-11.1h2v1.8C112.8,221.7,113.4,221.1,114,220.8z"
                      />
                      <path
                        className="st4"
                        d="M122.4,230.8c-0.9-0.4-1.5-1.2-2.1-2.1c-0.6-0.9-0.8-1.8-0.8-3c0-1,0.3-2.1,0.8-3s1.2-1.5,2.1-1.9
					c0.9-0.5,2-0.8,3-0.8s2.1,0.3,3,0.8c0.9,0.4,1.5,1.2,2.1,1.9c0.5,0.9,0.8,1.8,0.8,3c0,1.1-0.3,2.1-0.8,3
					c-0.4,0.9-1.2,1.5-2.1,2.1c-0.9,0.5-1.8,0.8-3,0.8S123.3,231.4,122.4,230.8z M127.2,229.3c0.6-0.3,1-0.8,1.4-1.3
					c0.3-0.6,0.4-1.3,0.4-2.1s-0.1-1.5-0.4-2.1s-0.8-1-1.4-1.3c-0.6-0.3-1.2-0.4-2-0.4s-1.4,0.2-2,0.4c-0.6,0.3-1,0.8-1.4,1.3
					c-0.3,0.6-0.5,1.3-0.5,2.1s0.2,1.5,0.5,2.1c0.3,0.6,0.8,1.1,1.4,1.3c0.6,0.3,1.2,0.5,2,0.5S126.7,229.8,127.2,229.3z"
                      />
                      <path
                        className="st4"
                        d="M142.3,231.6l-3.1-4.2l-3.3,4.2h-2.2l4.4-5.7l-4.2-5.4h2.2l3,4l3-4h2.2l-4.2,5.4l4.5,5.7H142.3z"
                      />
                      <path
                        className="st4"
                        d="M148.5,217.9c-0.3-0.3-0.4-0.6-0.4-0.9c0-0.3,0.1-0.8,0.4-0.9c0.3-0.3,0.6-0.4,1.1-0.4s0.8,0.2,1,0.3
					c0.3,0.3,0.5,0.6,0.5,0.9c0,0.5-0.1,0.8-0.5,0.9c-0.3,0.3-0.6,0.5-1,0.5S148.8,218.1,148.5,217.9z M148.5,220.3h2v11.1h-2V220.3
					z"
                      />
                      <path
                        className="st4"
                        d="M173.2,221.5c0.8,0.8,1.2,2,1.2,3.6v6.5h-2v-6.2c0-1.1-0.3-2-0.8-2.4c-0.4-0.6-1.2-0.8-2.1-0.8
					c-1,0-1.8,0.3-2.4,0.9c-0.6,0.6-0.9,1.5-0.9,2.7v5.7h-2.1v-6.2c0-1.1-0.3-2-0.8-2.4c-0.4-0.6-1.2-0.8-2.1-0.8
					c-1,0-1.8,0.3-2.4,0.9c-0.6,0.6-0.9,1.5-0.9,2.7v5.7h-1.9v-11.1h1.9v1.7c0.5-0.6,0.9-1.1,1.7-1.3c0.6-0.3,1.3-0.5,2.2-0.5
					s1.7,0.2,2.2,0.6s1.2,0.9,1.5,1.5c0.5-0.6,1.1-1.2,1.8-1.5c0.8-0.3,1.7-0.6,2.5-0.6C171.3,220.3,172.5,220.6,173.2,221.5z"
                      />
                      <path
                        className="st4"
                        d="M179.8,217.9c-0.3-0.3-0.4-0.6-0.4-0.9c0-0.3,0.2-0.8,0.4-0.9c0.3-0.2,0.6-0.4,1.1-0.4c0.4,0,0.8,0.2,1,0.3
					c0.3,0.3,0.5,0.6,0.5,0.9c0,0.5-0.2,0.8-0.5,0.9c-0.3,0.3-0.6,0.5-1,0.5C180.4,218.2,180.1,218.1,179.8,217.9z M179.8,220.3h2
					v11.1h-2V220.3z"
                      />
                      <path
                        className="st4"
                        d="M193.6,230.8c-0.3,0.3-0.6,0.5-1,0.6c-0.5,0.2-0.9,0.2-1.3,0.2c-1.1,0-2-0.3-2.6-0.9
					c-0.6-0.6-0.9-1.5-0.9-2.5V222h-1.9v-1.7h1.9v-2.4h2v2.4h3.2v1.7h-3.2v6c0,0.6,0.2,1.1,0.5,1.3c0.3,0.3,0.8,0.5,1.3,0.5
					s1.2-0.2,1.5-0.6L193.6,230.8z"
                      />
                      <path
                        className="st4"
                        d="M207.4,226.6h-9c0.2,1,0.6,1.8,1.3,2.4c0.8,0.6,1.7,0.9,2.7,0.9c1.3,0,2.4-0.5,3.2-1.3l1.1,1.3
					c-0.5,0.6-1.2,1-2,1.3c-0.8,0.3-1.7,0.4-2.5,0.4c-1.2,0-2.2-0.3-3.2-0.8c-0.9-0.4-1.7-1.2-2.1-2.1c-0.5-0.9-0.8-1.8-0.8-3
					c0-1,0.3-2.1,0.8-2.8c0.4-0.9,1.2-1.5,1.9-2.1s1.8-0.8,2.8-0.8c1.1,0,2,0.3,2.8,0.8c0.9,0.5,1.5,1.2,2,2.1
					c0.4,0.9,0.8,1.8,0.8,3C207.4,226.2,207.4,226.3,207.4,226.6z M199.5,222.9c-0.6,0.6-1,1.3-1.2,2.2h7.2
					c-0.2-0.9-0.4-1.7-1.2-2.2s-1.5-0.9-2.4-0.9C201,222,200.2,222.3,199.5,222.9z M203.2,216h2.6l-3.6,2.7h-2L203.2,216z"
                      />
                    </g>
                  </g>
                  <g className="st3">
                    <path
                      className="st4"
                      d="M109.9,50.6c4.3,3.6,6.6,8.7,6.6,15.3c0,6.6-2.2,11.7-6.6,15.3c-4.4,3.6-10.5,5.4-18.2,5.4H72.3v21.6h-1.8
				v-63h21.3C99.6,45,105.6,46.8,109.9,50.6z M108.9,79.8c3.9-3.3,5.8-7.9,5.8-14.1c0-6-2-10.8-5.8-14.1c-3.9-3.3-9.6-5-16.8-5H72.3
				v37.9h19.7C99.3,84.8,105,83.1,108.9,79.8z"
                    />
                    <path
                      className="st4"
                      d="M159.3,108l-17.5-22.7L124,108h-2.2l18.8-24.2l-17.7-22.3h2.2l16.5,21.1l16.5-21.1h2.1L142.6,84l18.9,24.1
				h-2.2V108z"
                    />
                  </g>
                  <line id="Ligne_16-2" className="st5" x1="82.3" y1="126.8" x2="149.8" y2="126.8" />
                </g>
              </g>
            </DnaAtom>
          </svg>
        </DesktopDna>
      </DNAWrapper>
    </Background>
  );
}
